import React from "react"
import theme from "theme"
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"info"} />
      <Helmet>
        <title>Наші послуги | стадiон «Спартак»</title>
        <meta name={"description"} content={"Підвищуйте рівень своєї гри"} />
        <meta
          property={"og:title"}
          content={"Наші послуги | стадiон «Спартак»"}
        />
        <meta
          property={"og:description"}
          content={"Підвищуйте рівень своєї гри"}
        />
        <meta
          property={"og:image"}
          content={"https://cool.novirexstill.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://cool.novirexstill.com/img/football.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://cool.novirexstill.com/img/football.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://cool.novirexstill.com/img/football.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://cool.novirexstill.com/img/football.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://cool.novirexstill.com/img/football.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://cool.novirexstill.com/img/football.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline1"
            lg-text-align="center"
            md-text-align="left"
          >
            Наші послуги
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            На стадiоні ми прагнемо створити виняткове середовище, де футболісти
            можуть досягати успіху та насолоджуватися улюбленою грою. Наш
            асортимент послуг розроблений таким чином, щоб задовольнити потреби
            всіх наших гостей, від гравців-любителів до професійних спортсменів.
            Ознайомтеся з нашими пропозиціями та знайдіть ідеальний варіант, що
            відповідає вашим футбольним прагненням.
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://cool.novirexstill.com/img/3.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://cool.novirexstill.com/img/4.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Оренда Поля
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Індивідуальні та групові бронювання: Незалежно від того, чи потрібне
            вам поле для індивідуальних тренувань або групового матчу, ми можемо
            задовольнити ваші потреби.
            <br />
            <br />
            Гнучкий графік: Бронюйте у зручний для вас час за допомогою нашої
            простої у використанні онлайн-системи.
            <br />
            <br />
            Спорядження надається: Від м'ячів до сіток - у нас є все необхідне
            для гри.
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Навчальні програми
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            Лекції та воркшопи: Вдосконалюйте свої навички на наших заняттях під
            керівництвом експертів.
            <br />
            <br />
            Молодіжні тренінги: Спеціальні заняття для молодих гравців, які
            прагнуть навчитися основам футболу.
            <br />
            <br />
            Семінари для дорослих: Просунуті стратегії та фітнес-поради для
            досвідчених гравців.
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://cool.novirexstill.com/img/5.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://cool.novirexstill.com/img/6.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Проведення заходів
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Турніри та ліги: Проводьте свої футбольні заходи на нашому
            першокласному майданчику.
            <br />
            <br />
            Корпоративні кубки: Ідеально підходить для тімбілдингу, організуйте
            футбольний турнір для своєї компанії.
            <br />
            <br />
            Місцеві ліги: Беріть участь або створіть власну лігу прямо тут, на
            нашій арені.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
